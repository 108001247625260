.searchBox {
  width: 90%;
    height: 86px;
    max-width: 764px;
    border: 2.372px solid white;
    border-radius: 30px;
    padding: 10px;
    margin-top: 30px !important;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(28.73729705810547px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.selectWrapper{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    border-radius: 31.427px;
    background: linear-gradient(180deg, #FFF54B 0%, #FCB910 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset;
}
select{
    width: 100%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.1rem;
    color: #212121;
    appearance: none;
}
.selectWrapper svg {
  position: absolute;
  right: 1.4rem;
  pointer-events: none;
  color: #212121;
}
.searchWrapper{
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 31.427px;
}
.input{
    outline: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 1rem;
}
input::placeholder{
    color:#fff !important;
}
.searchIcon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(180deg, #FFF54B 0%, #F4C952 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon{
    font-size: 1.2rem;
    color: #212121;
}

.change-page-button {
  padding: 0 !important;
  position: absolute !important;
  margin-top: 158px !important;
  z-index: 2;
}
.change-page-button:hover {
  box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.25);
}

.course-cat-name-text {
  color: #212121;
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.course-cat-name {
  display: flex;
  width: 45%;
  column-gap: 10px;
  padding-bottom: 5px;
  align-items: center;
  border-bottom: 3px solid #2596BE;
}

.student-count-rating {
  color: #939393;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.instructor-name {
  color: #FFF;
  font-style: normal;
  display: flex;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: -0.014px;
  padding-left: 16px;
  text-align: center;
  text-transform: capitalize;
}

.course-price {
  color: #00BE10;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  margin-right: 12px;
}

.course-price-discount {
  color: grey;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  margin-right: 12px;
  margin-left: 4px;
  text-decoration-line: line-through;
  text-decoration-color: red;
}

.add-cart-button {
  background: linear-gradient(133deg, #F93A3A -26.6%, #A81FE1 118.85%);
  line-height: 1.2 !important;
}

.added-cart-btn {
  color: #00BE10 !important;
  background: #E9E9E9;
  line-height: 1.2 !important;
}

.add-cart-btn__wishlist {
  background: white;
  color: #316773 !important;
  line-height: 1.2 !important;
}

.add-cart-btn__wishlist-text {
  color: #316773 !important;
  text-transform: capitalize !important;
}

.add-cart-text {
  color: #FFF !important;
  text-transform: capitalize !important;
}

.add-cart-hover-text {
  color: black !important;
  text-transform: capitalize !important;
}

.added-cart-text {
  color: #008C0C !important;
  font-style: normal;
  text-transform: capitalize !important;
}

@media screen and (max-width: 1370px) {
  .change-page-button {
      padding: 0 !important;
      position: absolute !important;
      margin-top: 158px !important;
      z-index: 2;
    }
  
    .change-page-button:hover {
      box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.25);
    }
  
    .course-cat-name-text {
      font-size: 10px;
    }
  
    .course-cat-name {
      display: flex;
      max-width: 70px;
      column-gap: 10px;
      margin-bottom: 5px;
      align-items: center;
    }
  
    .add-cart-button {
      background: linear-gradient(133deg, #F93A3A -26.6%, #A81FE1 118.85%);
    }
  
    .added-cart-btn {
      background: #E9E9E9;
    }
}

@media screen and (min-width: 600px) {
    .searchBox{
      width: 90%;
       flex-direction: row;
       justify-content: space-between;
       height: auto;
       border-radius: 43.286px;
       padding: 8px;
    }
    .selectWrapper{
        width: 170px;
        height: 62.438px;
        padding: 0 20px;
    }
    select{
        font-size: 1.2rem;
    }
    .searchWrapper{
        width: calc(100% - 219px);
        margin-top: 0;
    }
    .input{
        width: calc(100% - 60px)
    }
    .searchIcon{
        width: 50px;
        height: 50px;
    }
    .icon{
        font-size: 1.8rem;
    }
    }
@media screen and (min-width: 650px) {
    .selectWrapper{
        width: 219px;
    }
    .searchWrapper{
        width: calc(100% - 270px);
        margin-top: 0;
    }
}

.container {
    position: relative;
  }
  
  .background {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7); 
    color: white;
    padding: 10px;
  }
  
  .container:hover .background {
    display: block;
  }
  

.launchTrendCardMain {
    display: grid;
    grid-template-columns: repeat(4, minmax(23%, 1fr));
    gap: 10px;
    width: 100%;
    margin: 0 55px;
  }

.bundleCourseCardMain {
    display: grid;
    grid-template-columns: repeat(3, minmax(23%, 1fr));
    gap: clamp(8px, 2vw, 75px);
    width: 100%;
    margin: 0 55px;
  }

  .list-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .grid-card {
    display: grid;
    grid-template-columns: repeat(4, minmax(23%, 1fr));
    gap: 10px;
    width: 100%;
  }

.container-auto-margin {
  margin: 0px 55px;
}

@media (min-width:1441px) and (max-width: 1992px) {
  .container-auto-margin {
    margin: 0 auto;
  }
}

@media (min-width:1080px) and (max-width: 1330px) {
  .container-auto-margin {
    margin: 0px 32px;
  }
}
  
  .card {
    overflow: hidden;
    position: relative;
    background-color: white;
    border-radius: 15px;
    min-height: 378px;
    max-height: 28em;
    text-decoration: none;
    color: inherit;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  }

  .card .card-thumbnail {
    border-radius: 11px;
    height: 160px;
  }

  .bundle-course .bundle-course-image {
    border-radius: 11px; 
    width: 100%; 
    height: 110px;
  }
 
  .card.bundle-course {
    min-height: 34em;
    max-height: 34em;
  }

  .card.wishlist-card {
    min-height: 30em;
    max-height: 30em;
  }

  
  .card .card-content .card-image, .card_list .card-content .card-image {
    position: relative;
    padding: 12px;
  }
 
 
  
  .card .card-content.active, .card_list .card-content.active {
    opacity: 0;
    transition: 0.3s all ease-in-out;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(1, 123, 147, 0.93) 0%,
      rgba(0, 33, 41, 0.97) 100%
    );
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
  }

  .card .card-content.active {
    flex-direction: column;
  }
  
  .card:hover .card-content.active {
    opacity: 1;
  }
  
  .card_list {
    width: 100%;
    height: 190px;
    overflow: hidden;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    background-color: white;
    border-radius: 15px;
    color: inherit;
    text-decoration: none;
  }
 
  .card_list:hover .card-content.active {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(1, 123, 147, 0.93) 0%,
      rgba(0, 33, 41, 0.97) 100%
    );
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 1;
  }

@media screen and (min-width: 1440px){
    .launchTrendCardMain {
      gap: clamp(8px, 1.5vw, 75px) !important;
    }
}
@media screen and (min-width: 1441px){
    .grid-card {
      grid-template-columns: repeat(5, minmax(19%, 1fr)) !important;
    }
}
